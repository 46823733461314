
<template lang="pug">
table-picker(
  :data='reportTypes'
  :id='id'
  :multiple='multiple'
  :onlyIds='onlyIds'
  :isLoading='isLoading'
  v-model='modelValue'
)
</template>

<script>
import TablePicker from '@/components/shared/TablePicker'
import reportTypesService from '@/services/report-types.service'

export default {
  components: { TablePicker },
  props: {
    id: { type: String },
    value: { type: [String, Array] },
    multiple: { type: Boolean },
    onlyIds: { type: Boolean }
  },
  data () {
    return {
      modelValue: null,
      isLoading: false,
      reportTypes: []
    }
  },
  async created () {
    await this.getReportTypes()
    this.modelValue = this.value
  },
  watch: {
    modelValue () {
      this.$emit('input', this.modelValue)
    },
    value () {
      this.modelValue = this.value
    }
  },
  computed: {
    title () {
      return this.multiple ? 'Seleccione uno o varios equipos' : 'Seleccione un equipo'
    }
  },
  methods: {
    async getReportTypes () {
      this.isLoading = true
      const result = await reportTypesService.getAll()
      if (result) {
        this.reportTypes = result
      }
      this.isLoading = false
    }
  }
}
</script>
